.blogs-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
  }
  
  .blog-card {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    width: 450px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .blog-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .blog-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .blog-content {
    padding: 15px;
  }
  
  .blog-title {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .blog-description {
    font-size: 14px;
    color: #555;
    line-height: 1.5;
  }
  .read-more-button{
    background-color: #335511;
  }
  